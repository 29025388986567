import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Home/Home';
import Layout from './shared/Layout';
import About from './About/About';
import Davening from './Davening/Davening';
import Learning from './Learning/Learning';
import Events from './Events/Events';
import Community from './Community/Community';
import Committees from './Committees/Committees';

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/about',
          element: <About />,
          children: [
            {
              path: '/about/:currentTab',
              element: <About />,
            }
          ]
        },
        {path: '/davening',
          element: <Davening />,
          children: [
            {
              path: '/davening/:currentTab',
              element: <Davening />,
            }
          ]
        },
        {path: '/learning',
          element: <Learning />,
          children: [
            {
              path: '/learning/:currentTab',
              element: <Learning />,
            }
          ]
        },
        {path: '/events',
          element: <Events />,
          children: [
            {
              path: '/events/:currentTab',
              element: <Events />,
            }
          ]
        },
        {path: '/community',
          element: <Community />,
          children: [
            {
              path: '/community/:currentTab',
              element: <Community />,
            }
          ]
        },
        {path: '/committees',
          element: <Committees />,
          children: [
            {
              path: '/committees/:currentTab',
              element: <Committees />,
            }
          ]
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />;
};

export default App;
