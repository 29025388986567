import React from 'react';
import('./style.scss');
import { Outlet, NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import logo from '../../../assets/images/yi_logo.gif';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  toJewishDate,
  formatJewishDate,
  toHebrewJewishDate,
  formatJewishDateInHebrew
} from 'jewish-date';

const Layout = () => {
  return (
    <>
      <div
        className='top-bar container-fluid '
        style={{ backgroundColor: '#344A5D' }}
      >
        <Container>
          <Row className='text-white py-3'>
            <Col className='col-lg-4 col-md-4 col-xs-12 col-sm-12 d-none d-lg-block'>
              <span>
                {new Date().toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </span>{' '}
              <span
                style={{ whiteSpace: 'nowrap' }}
                className='border-start ms-2 ps-2'
              >
                {formatJewishDate(toJewishDate(new Date()))}
              </span>
            </Col>
            <Col className='text-end col-lg-8 col-md-8 col-xs-12 col-sm-12 d-none d-lg-block'>
              <span style={{ whiteSpace: 'nowrap' }}>314-727-1880</span>{' '}
              <span className='border-start ms-2 ps-2'>
                <a href='/announcements'>ANNOUNCEMENTS</a>
              </span>
              <span className='border-start ms-2 ps-2'>
                <a href='/calendar'>CALENDAR</a>
              </span>
              <span className='border-start ms-2 ps-2'>
                <a href='/about/contact'>CONTACT</a>
              </span>
              <span className='border-start ms-2 ps-2'>
                <a href='/holidays2024'>HOLIDAYS 2024</a>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar
        collapseOnSelect
        expand='lg'
        style={{ fontSize: '1.2rem', color: '#006494' }}
      >
        {' '}
        <Container>
          <Navbar.Brand to='/' as={NavLink}>
            <img src={logo} className='ps-3' style={{ height: '100px' }} />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className='justify-content-end'>
            <Row className=''>
              <div className='col-6 col-lg-12'>
                <Nav className='ms-auto '>
                  {/* <Nav.Link as={NavLink} to='/'>Home</Nav.Link> */}
                  {/* <NavDropdown title='About'>
              <NavDropdown.Item>History</NavDropdown.Item>
              <NavDropdown.Item>Rabbi</NavDropdown.Item>
            </NavDropdown> */}
                  <Nav.Link as={NavLink} to='/about' href='/about'>
                    About
                  </Nav.Link>
                  <Nav.Link as={NavLink} to='/davening' href='/davening'>
                    Davening
                  </Nav.Link>
                  <Nav.Link as={NavLink} to='/learning' href='/learning'>
                    Learning
                  </Nav.Link>
                  <Nav.Link as={NavLink} to='/events' href='/events'>
                    Events
                  </Nav.Link>
                  <Nav.Link as={NavLink} to='/community' href='/community'>
                    Community
                  </Nav.Link>
                  <Nav.Link as={NavLink} to='/committees' href='/committees'>
                    Committees
                  </Nav.Link>
                  {/* <Nav.Link as={NavLink} to='/donate' href='/donate'>
                    Donate
                  </Nav.Link>
             
               
              
                  <Nav.Link as={NavLink} to='/pictures' href='/pictures'>
                    Pictures
                  </Nav.Link> */}
                  
                </Nav>
              </div>
              <div className='col-6 d-lg-none'>
                <Nav>
                  <Nav.Link to='/announcments' href='/announcments'>
                    Announce-
                    <br />
                    ments
                  </Nav.Link>
                  <Nav.Link to='/calendar' href='/calendar'>
                    Calendar
                  </Nav.Link>
                  <Nav.Link to='/contact' href='/contact'>
                    Contact
                  </Nav.Link>
                  <Nav.Link to='/holidays2024' href='/holidays2024'>
                    Holidays 2024
                  </Nav.Link>
                </Nav>
              </div>
            </Row>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
      {/* <h1>Footer</h1> */}
    </>
  );
};

export default Layout;
