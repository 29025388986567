import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';

const Community = () => {
  const { currentTab } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState();

  const tabs = ['', ];

  useEffect(() => {
    setActiveTabIndex(
      !currentTab ? 0 : tabs.findIndex((el) => el === currentTab)
    );
  }, []);

  const handleTabChange = (newTabIndex) => {
    setActiveTabIndex(newTabIndex);
    if (newTabIndex === 0) {
      window.history.replaceState(null, null, '/community');
    } else {
      window.history.replaceState(null, null, `/community/${tabs[newTabIndex]}`);
    }
  };

  return (
    <>
      <div className='page-header text-center d-flex container-fluid justify-content-center align-items-center'>
        <h1>Community</h1>
      </div>
      <TabView
        className='mt-1 nav-ribbon'
        activeIndex={activeTabIndex}
        onTabChange={(e) => handleTabChange(e.index)}
        scrollable
      >
        <TabPanel header='Community'>
          <Container>
    
          </Container>
        </TabPanel>
      </TabView>
    </>
  );
};

export default Community;
