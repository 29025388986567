import React from 'react';
// import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from '../components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
// import 'primereact/resources/primereact.min.css';


// import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';

const root = createRoot(document.getElementById('react-entrypoint'))

document.addEventListener('DOMContentLoaded', () => {
  // const el = document.getElementById('react-entrypoint');
  // if (el) {
    root.render(
      // <React.StrictMode>
        <PrimeReactProvider value={{ripple: true}}>
          <App />
        </PrimeReactProvider>
      // </React.StrictMode>
     
    );
  // }
});
