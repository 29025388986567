import React, {useEffect, useRef} from 'react'
import('./style.scss');
import { Carousel } from 'primereact/carousel';
// import Carousel from 'react-bootstrap/Carousel'
import Img1 from '../../../assets/images/purim/1.jpg'   
import Img2 from '../../../assets/images/purim/2.jpg'  
import Img3 from '../../../assets/images/purim/3.jpg'  
import Img4 from '../../../assets/images/purim/4.jpg'  
import Img5 from '../../../assets/images/purim/5.jpg'  
import Img6 from '../../../assets/images/purim/6.jpg'  
import Img7 from '../../../assets/images/purim/7.jpg'  
import Img8 from '../../../assets/images/purim/8.jpg'  
import Img9 from '../../../assets/images/purim/9.jpg'  
import Img10 from '../../../assets/images/purim/10.jpg'  
// import Image from 'react-bootstrap/Image'
import {Image} from 'primereact/image'

const PhotoCarousel = ({}) => {


const photos = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10]

// useEffect(() => {
//   console.log('here');
//   ref.current.startAutoplay()
// },[])

const photoTemplate = (photo) => {
return <Image imageStyle={{borderRadius: "20px 20px 20px 20px"}} src={photo} width="335"  />
}


return <>
  { <Carousel autoFocus className='photo-carousel' value={photos} numVisible={4} numScroll={4} autoplayInterval={3000} circular itemTemplate={photoTemplate}/>}

</>



}

export default PhotoCarousel